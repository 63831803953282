//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberQueryPage, memberDeleteByIds, memberExport, memberUpdateById, memberFreeze, memberCreateDraw, integralFollow, userintegral, userintegraladd, unlock, restoreByIds } from '@/api/member/member';
import addDynamic from './components/addDynamic';
import addFreeze from './components/addFreeze';
import userFans from './components/userFans';
import userLabes from './components/userLabes';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime, handleExport } from '@/utils';
import { registerType, virtualFlagType, whiteEnableType } from '@/utils/enum';
export default {
  name: 'UserControl',
  components: {
    addDynamic: addDynamic,
    addFreeze: addFreeze,
    userFans: userFans,
    userLabes: userLabes
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      dataStatusList: [{
        value: 0,
        label: '否'
      }, {
        value: 1,
        label: '是'
      }],
      creatorFlagList: [{
        value: 0,
        label: '否'
      }, {
        value: 1,
        label: '是'
      }],
      registerType: registerType,
      virtualFlagType: virtualFlagType,
      whiteEnableType: whiteEnableType,
      value: '',
      input: '',
      comeFrom: [],
      tableFrom: {
        page: 1,
        limit: 20,
        mobile: '',
        regType: '',
        virtualFlag: '',
        whiteEnable: '',
        sourceId: '',
        email: '',
        nickname: '',
        outId: '',
        dataStatus: 1 // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      dialogTableVisible: false,
      dialogIntegral: false,
      gridData: [],
      form: {
        memberId: 0,
        amount: 0,
        remark: ''
      },
      Integraldata: {
        memberId: 0,
        page: 1,
        limit: 20,
        total: 0,
        accountType: 1,
        remark: ''
      },
      status: 0
    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    unlookcode: function unlookcode(id, type) {
      var _this = this;

      this.$confirm("\u662F\u5426\u89E3\u9501\u5F53\u524D\u7528\u6237\u9A8C\u8BC1\u7801 \uFF1F", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var par = {
          memberId: id,
          type: type
        };
        unlock(par).then(function (res) {
          _this.$message.success('解锁成功');
        }).catch(function (res) {
          _this.$message.error('解锁失败');
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88"
        });
      });
    },
    // 获取粉丝列表
    getfans: function getfans(id) {
      this.$refs.userFans.setting.visible = true;
      this.$refs.userFans.Integraldata.memberId = id;
      this.$refs.userFans.fans.memberId = id;
      this.$refs.userFans.getFansList();
    },
    getlabes: function getlabes(id) {
      this.$refs.userLabes.dialog = true;
      this.$refs.userLabes.Integraldata.memberId = id;
      this.$refs.userLabes.fans.memberId = id;
      this.$refs.userLabes.getFansList();
    },
    // 生成抽奖
    generateLotteryWinner: function generateLotteryWinner(row) {
      var _this2 = this;

      var par = {
        memberId: row.id
      };
      memberCreateDraw(par).then(function (res) {
        row.freezeTime = '';

        _this2.$message.success('生成抽奖成功');
      }).catch(function (res) {
        _this2.$message.error('生成抽奖失败');
      });
    },
    handlePrize: function handlePrize(row) {
      var _this3 = this;

      this.$confirm("\u662F\u5426\u589E\u52A0\u4E00\u6B21\u62BD\u5956\u673A\u4F1A \uFF1F", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.generateLotteryWinner(row);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88"
        });
      });
    },
    handleFreeze: function handleFreeze(row) {
      if (row.freezeTime) {
        this.$refs.addFreeze.setting.title = '编辑冻结时间';
      } else {
        this.$refs.addFreeze.setting.title = '添加冻结时间';
      }

      this.$refs.addFreeze.freezeId = row.id;
      this.$refs.addFreeze.freezeVal = row.freezeTime;
      this.$refs.addFreeze.setting.visible = true; // memberFreeze(this.tableFrom).then(res => {
      //   handleExport(res, '用户')
      //   this.listLoading = false
      // }).catch(() => {
      //   this.listLoading = false
      // })
    },
    cancelFreeze: function cancelFreeze(row) {
      var _this4 = this;

      var par = {
        freezeTime: '',
        id: row.id
      };
      memberFreeze(par).then(function (res) {
        row.freezeTime = '';

        _this4.$message.success('修改成功'); // 重置
        // this.handleClose()

      }).catch(function (res) {
        _this4.$message.error('修改失败');
      });
    },
    downExport: function downExport() {
      var _this5 = this;

      this.listLoading = true;
      memberExport(this.tableFrom).then(function (res) {
        handleExport(res, '用户');
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    handleOpen: function handleOpen(row) {
      var _this6 = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u5FEB\u8BAF"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.setSave(row);
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '开启' : '关闭')
        });
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        nickname: '',
        mobile: '',
        regType: '',
        virtualFlag: '',
        whiteEnable: '',
        sourceId: '',
        email: '',
        creatorFlag: '',
        page: 1,
        limit: 20
      };
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      this.tableSelection = row;
      this.multipleSelection = row.map(function (item) {
        return item.id;
      });
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par; // this.$refs.addDynamic.memberPostFollow()

        this.$refs.addDynamic.setting.title = '编辑用户';
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          nickname: '',
          // 昵称
          sourceId: '',
          // 来源
          outId: '',
          // 外部Id
          header: '',
          // 头像
          mobile: '',
          // 手机号
          countryCode: '86',
          // 手机号区号
          email: '',
          // 邮箱
          sign: '',
          // 个性签名
          likeNumActual: '',
          // 虚拟点赞量
          discussNumActual: '',
          // 虚拟评论量
          forwardNumActual: '',
          // 虚拟转发量
          visitNumActual: '',
          // 虚拟浏览量
          virtualFlag: 0,
          // 虚拟用户
          memberType: 1,
          // 用户类型 默认普通用户
          regType: 0,
          // 注册类型 默认手机注册
          whiteEnable: false,
          // 是否白名单
          hotEnable: 0,
          // 是否热门
          creatorFlag: 0,
          // 是否创作者  0否 1是
          creatorRank: 0,
          // 创作者排序权重  越大越靠前
          creatorSelectedFlag: 0,
          // 是否创作者精选  0否 1是
          creatorRecommendFlag: 0 // 是否创作者推荐  0否 1是

        };
        this.$refs.addDynamic.setting.title = '添加用户';
      }

      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this7 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      memberDeleteByIds(par.ids).then(function (res) {
        _this7.$message.success('删除成功');

        _this7.getList();
      });
    },
    restore: function restore() {
      var _this8 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      restoreByIds(par.ids).then(function (res) {
        _this8.$message.success('恢复成功');

        _this8.getList();
      });
    },
    setSave: function setSave(row) {
      var _this9 = this;

      row.isShow = !row.isShow;
      memberUpdateById(row).then(function (res) {
        _this9.$message.success('修改成功');
      }).catch(function (res) {
        row.isShow = !row.isShow;

        _this9.$message.error('修改失败');
      });
    },
    // 列表
    getList: function getList() {
      var _this10 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      memberQueryPage(this.tableFrom).then(function (res) {
        _this10.listData.list = res.list;
        _this10.listData.total = res.totalCount;
        _this10.listLoading = false;
      }).catch(function () {
        _this10.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this11 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this11.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    showIntegralDia: function showIntegralDia(id, status) {
      this.form.memberId = id;
      this.dialogTableVisible = true;

      if (status == 1) {
        this.status = 1;
      } else {
        this.status = 0;
      }
    },
    addIntegralDia: function addIntegralDia() {
      var _this12 = this;

      userintegraladd(this.form).then(function (res) {
        _this12.dialogTableVisible = false;

        _this12.$message.success('增加积分成功');

        _this12.form = {
          memberId: 0,
          amount: 0,
          remark: ''
        };

        if (_this12.status == 1) {
          integralFollow(_this12.Integraldata).then(function (res) {
            _this12.Integraldata.list = res.list;
            _this12.Integraldata.total = res.totalCount;
            _this12.gridData = res.list;
          }).catch(function () {});
        }
      }).catch(function () {
        _this12.$message.success('增加积分失败');
      });
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      this.Integraldata.limit = val;
      this.showIntegralList();
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      this.showIntegralList();
    },
    getintegralFollow: function getintegralFollow() {
      var _this13 = this;

      integralFollow(this.Integraldata).then(function (res) {
        _this13.Integraldata.list = res.list;
        _this13.Integraldata.total = res.totalCount;
        _this13.gridData = res.list;
      }).catch(function () {});
    },
    getformid: function getformid(id) {
      this.Integraldata.remark = '';
      this.dialogIntegral = true;
      this.form.memberId = id;
      this.Integraldata.memberId = id;
      this.showIntegralList();
    },
    showIntegralList: function showIntegralList() {
      var _this14 = this;

      integralFollow(this.Integraldata).then(function (res) {
        _this14.gridData = res.list;
        _this14.Integraldata.total = res.totalCount;
        _this14.gridData = res.list;
      }).catch(function () {});
    }
  }
};