//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { appFailauth } from '@/api/ai/index';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { parseTime, handleExport } from '@/utils';
import { languageType } from '@/utils/enum';
export default {
  name: 'CrawlerControl',
  components: {
    VueJsonPretty: VueJsonPretty
  },
  data: function data() {
    return {
      jsonData: {
        name: "John Doe",
        age: 30,
        city: "New York"
      },
      languageType: languageType,
      tableFrom: {
        page: 1,
        limit: 20,
        content: '',
        createBy: '',
        date: '',
        deviceId: '',
        logUrl: ''
      },
      translateFlagSelect: [{
        value: 0,
        label: '否'
      }, {
        value: 1,
        label: '是'
      }],
      listData: {
        list: [],
        total: 0
      },
      diaform: {
        title: '详情'
      },
      crawkerData: {
        language: '',
        type: 1,
        content: '',
        weight: 1,
        showEnable: 1
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',
      addtype: 0,
      multipleSelection: [],
      options: [],
      //tabs
      activeName: '1',
      value1: ''
    };
  },
  mounted: function mounted() {
    // this.getoptions()
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    downurl: function downurl(url) {
      var a = url.replace("http", "https");
      window.location.href = a;
    },
    settimes: function settimes(e) {
      if (e == null) {
        this.tableFrom.createTimeStart = '';
        this.tableFrom.createTimeEnd = '';
      } else {
        this.tableFrom.createTimeStart = e[0];
        this.tableFrom.createTimeEnd = e[1];
      }
    },
    formatDateTime3: function formatDateTime3(dateString) {
      // 将 ISO 8601 格式的时间字符串转换为 Date 对象
      var date = new Date(dateString); // 获取年、月、日、小时、分钟、秒

      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始

      var day = String(date.getDate()).padStart(2, '0');
      var hours = String(date.getHours()).padStart(2, '0');
      var minutes = String(date.getMinutes()).padStart(2, '0');
      var seconds = String(date.getSeconds()).padStart(2, '0'); // 返回格式化后的时间字符串

      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    deriveList: function deriveList() {
      aifeedbackExport(this.tableFrom).then(function (res) {
        handleExport(res, '反馈列表');
      });
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    getoptions: function getoptions() {
      var _this = this;

      spiderSourceQueryList({}).then(function (res) {
        _this.options = res;
      }).catch(function () {});
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        createTimeEnd: '',
        createTimeStart: '',
        nickname: '',
        usefulFlag: ''
      };
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    handleFreeze: function handleFreeze(row) {
      this.diaform.title = '详情';
      this.dialogFormVisible = true;
      this.crawkerData = row;
      this.addtype = 1;
    },
    // 删除
    delCelebrity: function delCelebrity(row) {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      spiderVideoDeleteByIds(par.ids).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    // 导出
    addCelebrity: function addCelebrity() {
      aifeedbackExport(this.tableFrom).then(function (res) {}).catch(function () {});
    },
    // 列表
    getList: function getList() {
      var _this3 = this;

      appFailauth(this.tableFrom).then(function (res) {
        _this3.listData = res;
      }).catch(function () {});
    },
    // 更新
    crawlerupd: function crawlerupd() {
      var _this4 = this;

      if (this.addtype === 1) {
        aiUpdateById(this.crawkerData).then(function (res) {
          _this4.dialogFormVisible = false;
          _this4.crawkerData = {
            language: '',
            type: 1,
            content: '',
            weight: 1,
            showEnable: 1
          };

          _this4.$message.success('修改成功');
        }).catch(function () {
          _this4.$message.error('修改失败');
        });
      } else {
        aiSave(this.crawkerData).then(function (res) {
          _this4.dialogFormVisible = false;

          _this4.$message.success('新增成功');

          _this4.getList();
        }).catch(function () {
          _this4.$message.error('新增失败');
        });
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.listData.total = val;
      console.log(this.tableFrom);
      this.getList();
    },
    pageChange: function pageChange(val) {
      this.tableFrom.page = val;
      this.getList();
    }
  }
};