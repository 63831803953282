var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入昵称" },
                        model: {
                          value: _vm.formModal.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "username", $$v)
                          },
                          expression: "formModal.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号码" },
                        model: {
                          value: _vm.formModal.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "mobile", $$v)
                          },
                          expression: "formModal.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.formModal.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "email", $$v)
                          },
                          expression: "formModal.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "password", $$v)
                          },
                          expression: "formModal.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配置角色", prop: "roleIdList" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.roleIdList,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "roleIdList", $$v)
                            },
                            expression: "formModal.roleIdList",
                          },
                        },
                        _vm._l(_vm.listData, function (item) {
                          return _c("el-option", {
                            key: item.roleId,
                            staticStyle: { width: "100%" },
                            attrs: { label: item.roleName, value: item.roleId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "锁定", prop: "status" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          value: String(_vm.formModal.status),
                          "active-value": "0",
                          "inactive-value": "1",
                          name: _vm.formModal.username,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updswitch(_vm.formModal)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }