function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/* eslint-disable no-undef */
// import Vue from 'vue'
// import Router from 'vue-router'
Vue.use(VueRouter);
/* Layout */

import Layout from '@/layout';
/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: '数据看板',
    meta: {
      title: '数据看板',
      icon: 'dashboard',
      affix: true
    }
  }]
}, // 名人
{
  path: '/famousPerson',
  component: Layout,
  redirect: '/famousPerson/celebrityMan',
  name: 'famousPerson',
  meta: {
    title: '名人',
    icon: 'el-icon-s-help'
  },
  hidden: true,
  children: [{
    path: 'celebrityMan',
    name: 'CelebrityMan',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/famousPerson/famousPersonMent/index'));
      });
    },
    meta: {
      title: '名人管理',
      icon: 'el-icon-s-help'
    }
  }, {
    path: 'dynamicMent',
    name: 'DynamicMen',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/famousPerson/dynamicMent/index'));
      });
    },
    meta: {
      title: '名人动态管理',
      icon: 'tree'
    }
  }, {
    path: 'addDynamicMen',
    name: 'AddDynamicMen',
    meta: {
      title: '',
      icon: 'el-icon-s-help',
      alias: '名人新增动态'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/famousPerson/dynamicMent/components/addDynamicMen.vue'));
      });
    }
  }, {
    path: 'commentMent',
    name: 'CommentMen',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/famousPerson/commentMent/index'));
      });
    },
    meta: {
      title: '名人评论管理',
      icon: 'tree'
    }
  }]
}, // 动态
{
  path: '/dynamic',
  component: Layout,
  name: 'dynamic',
  meta: {
    title: '动态',
    icon: 'el-icon-s-help'
  },
  hidden: true,
  children: [{
    path: 'topManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dynamic/topManagement/index'));
      });
    },
    // Parent router-view
    name: 'InformationDyn',
    meta: {
      title: '话题管理',
      icon: 'el-icon-s-help'
    },
    children: [{
      path: 'topManagementHome',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dynamic/topManagement/topManagementHome/index'));
        });
      },
      name: 'topManagementHome',
      meta: {
        title: '话题管理首页'
      }
    }, {
      path: 'topicTag',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dynamic/topManagement/topicTag/index'));
        });
      },
      name: 'topicTag',
      meta: {
        title: '话题标签'
      }
    }, {
      path: 'subjectDynamic',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dynamic/topManagement/subjectDynamic/index'));
        });
      },
      name: 'subjectDynamic',
      meta: {
        title: '问答标签'
      }
    } // ,
    // {
    //   path: 'commentsManagement',
    //   component: () => import(/* webpackChunkName: "commentsManagement" */ '@/views/dynamic/topManagement/commentsManagement/index'),
    //   name: 'commentsManagement',
    //   meta: { title: '评论管理' }
    // }
    ]
  }, // 暂时不要
  // {
  //   path: 'questionsManagement',
  //   name: 'questionsManagement',
  //   component: () => import(/* webpackChunkName: "questionsManagement" */ '@/views/dynamic/questionsManagement/index'),
  //   meta: { title: '问答管理', icon: 'tree' },
  //   children: [
  //     {
  //       path: 'questionsManagementHome',
  //       component: () => import(/* webpackChunkName: "questionsManagementHome" */ '@/views/dynamic/questionsManagement/questionsManagementHome/index'),
  //       name: 'questionsManagementHome',
  //       meta: { title: '问答管理首页' }
  //     },
  //     {
  //       path: 'questionsTig',
  //       component: () => import(/* webpackChunkName: "questionsTig" */ '@/views/dynamic/questionsManagement/questionsTig/index'),
  //       name: 'questionsTig',
  //       meta: { title: '问答标签' }
  //     },
  //     {
  //       path: 'questionsComment',
  //       component: () => import(/* webpackChunkName: "questionsComment" */ '@/views/dynamic/questionsManagement/questionsComment/index'),
  //       name: 'questionsComment',
  //       meta: { title: '评论管理' }
  //     }
  //   ]
  // },
  {
    path: 'communityDynamic',
    name: 'communityDynamic',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dynamic/communityDynamic/index'));
      });
    },
    meta: {
      title: '社区动态',
      icon: 'tree'
    },
    children: [{
      path: 'communityDynamictHome',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dynamic/communityDynamic/communityDynamicHome/index'));
        });
      },
      name: 'CommunityDynamicHome',
      meta: {
        title: '社区动态首页'
      }
    }, {
      path: 'addCommunity',
      name: 'AddCommunity',
      meta: {
        title: '',
        icon: 'el-icon-s-help',
        alias: '新增动态'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dynamic/communityDynamic/communityDynamicHome/components/addCommunity.vue'));
        });
      }
    }, // {
    //   path: 'classifyManagement',
    //   component: () => import(/* webpackChunkName: "ClassifyManagement" */ '@/views/dynamic/communityDynamic/classifyManagement/index'),
    //   name: 'ClassifyManagement',
    //   meta: { title: '分类管理' }
    // },
    {
      path: 'communityComment',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dynamic/communityDynamic/communityComment/index'));
        });
      },
      name: 'communityComment',
      meta: {
        title: '动态评论管理'
      }
    } // ,
    // {
    //   path: 'spiderVideo',
    //   component: () => import(/* webpackChunkName: "communityDynamicHome" */ '@/views/dynamic/communityDynamic/spiderVideo/index'),
    //   name: 'SpiderVideo',
    //   meta: { title: '视频爬取计划  ' }
    // }
    ]
  }]
}, // 讯息
{
  path: '/informine',
  component: Layout,
  // redirect: '/informine/information',
  name: 'Informine',
  hidden: true,
  meta: {
    title: '讯息',
    icon: 'nested'
  },
  children: [{
    path: 'information',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/informine/information/index'));
      });
    },
    // Parent router-view
    name: 'Information',
    meta: {
      title: '资讯管理'
    },
    children: [{
      path: 'informationHome',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/informine/information/informationHome'));
        });
      },
      name: 'informationHome',
      meta: {
        title: '资讯首页'
      }
    }, {
      path: 'slideShow',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/informine/information/slideShow'));
        });
      },
      name: 'slideShow',
      meta: {
        title: '轮播图'
      }
    }, {
      path: 'slideShowPlace',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/informine/information/slideShowPlace'));
        });
      },
      name: 'SlideShowPlace',
      meta: {
        title: '轮播图位置'
      }
    }, {
      path: 'classify',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/informine/information/classify'));
        });
      },
      name: 'classify',
      meta: {
        title: '分类'
      }
    }]
  }, {
    path: 'expressManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/informine/expressManagement/index'));
      });
    },
    name: 'ExpressManagement',
    meta: {
      title: '快讯管理'
    }
  }, {
    path: 'industry',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/informine/industry/index'));
      });
    },
    name: 'Industry',
    meta: {
      title: '行业日历'
    }
  }]
}, {
  path: '/currency',
  component: Layout,
  redirect: '/currency/amonnt',
  name: 'currency',
  hidden: true,
  meta: {
    title: '币圈',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'amonnt',
    name: 'Amonnt',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/currency/amonnt/index'));
      });
    },
    meta: {
      title: '币种',
      icon: 'el-icon-s-help'
    }
  }, {
    path: 'addAmonnt',
    name: 'AddAmonnt',
    meta: {
      title: '',
      icon: 'el-icon-s-help',
      alias: '新增币种'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/currency/amonnt/components/addAmonnt.vue'));
      });
    }
  }, {
    path: 'addBourse',
    name: 'AddBourse',
    meta: {
      title: '',
      icon: 'el-icon-s-help',
      alias: '新增交易所'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/currency/bourse/components/addBourse.vue'));
      });
    }
  }, {
    path: 'bourse',
    name: 'Bourse',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/currency/bourse/index'));
      });
    },
    meta: {
      title: '交易所',
      icon: 'tree'
    }
  }, {
    path: 'plate',
    name: 'Plate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/currency/plate/index'));
      });
    },
    meta: {
      title: '板块',
      icon: 'tree'
    }
  }, {
    path: 'addPlate',
    name: 'AddPlate',
    meta: {
      title: '',
      icon: 'el-icon-s-help',
      alias: '新增板块'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/currency/plate/components/addPlate.vue'));
      });
    }
  }]
}, {
  path: '/invitation',
  component: Layout,
  name: 'Invitation',
  hidden: true,
  meta: {
    title: '活动',
    icon: 'el-icon-message-solid'
  },
  children: [{
    path: 'activity',
    name: 'Activity',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/invitation/activity/index'));
      });
    },
    meta: {
      title: '邀请记录',
      icon: 'el-icon-message-solid'
    }
  }]
}, {
  path: '/memberCreator',
  component: Layout,
  name: 'MemberCreator',
  hidden: true,
  meta: {
    title: '创作者',
    icon: 'el-icon-message-solid'
  },
  children: [{
    path: 'memberCreatorList',
    name: 'MemberCreatorList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/memberCreator/memberCreatorList/index'));
      });
    },
    meta: {
      title: '创作者记录',
      icon: 'el-icon-s-custom'
    }
  }]
}, {
  path: '/users',
  component: Layout,
  name: 'users',
  hidden: true,
  meta: {
    title: '用户',
    icon: 'el-icon-user-solid'
  },
  children: [{
    path: 'usercontrol',
    name: 'UserControl',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userControl/index'));
      });
    },
    meta: {
      title: '用户管理',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'userintegral',
    name: 'userIntegral',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userIntegral/index'));
      });
    },
    meta: {
      title: '账户',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'usergroup',
    name: 'userGroup',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userGroup/index'));
      });
    },
    meta: {
      title: '群聊列表',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'userIntegrallist',
    name: 'userIntegrallist',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userIntegrallist/index'));
      });
    },
    meta: {
      title: '帐户流水汇总',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'usersummary',
    name: 'usersummary',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/usersummary/index'));
      });
    },
    meta: {
      title: '提现申请记录',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'userlabel',
    name: 'userLabel',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userlabel/index'));
      });
    },
    meta: {
      title: '用户标签页',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'usercomment',
    name: 'userComment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userComment/index'));
      });
    },
    meta: {
      title: '用户评论审核',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'userinfolist',
    name: 'userinfoList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/userinfolist/index'));
      });
    },
    meta: {
      title: '修改信息记录',
      icon: 'el-icon-user-solid'
    }
  }]
}, {
  path: '/activity',
  component: Layout,
  name: 'activity',
  hidden: true,
  meta: {
    title: '活动',
    icon: 'el-icon-user-solid'
  },
  children: [{
    path: 'win',
    name: 'win',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activity/win/index'));
      });
    },
    meta: {
      title: '中奖列表',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'wakuang',
    name: 'wakuang',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activity/wakuang/index'));
      });
    },
    meta: {
      title: '挖矿活动',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'configuration',
    name: 'configuration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activity/configuration/index'));
      });
    },
    meta: {
      title: '活动配置',
      icon: 'el-icon-user-solid'
    }
  }]
}, {
  path: '/crawler',
  component: Layout,
  name: 'crawler',
  hidden: true,
  meta: {
    title: '爬虫',
    icon: 'el-icon-user-solid'
  },
  children: [{
    path: 'crawlerControl',
    name: 'crawlerControl',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/crawler/crawlerControl/index'));
      });
    },
    meta: {
      title: '爬虫管理',
      icon: 'el-icon-user-solid'
    }
  }]
}, {
  path: '/ai',
  component: Layout,
  name: 'ai',
  hidden: true,
  meta: {
    title: 'ai设置',
    icon: 'el-icon-user-solid'
  },
  children: [{
    path: 'aimessage',
    name: 'aiMessage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/ai/aiMessage/index'));
      });
    },
    meta: {
      title: 'ai消息管理',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'aicontent',
    name: 'aiContent',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/ai/aiContent/index'));
      });
    },
    meta: {
      title: 'ai内容反馈',
      icon: 'el-icon-user-solid'
    }
  }, {
    path: 'aiflash',
    name: 'aiFlash',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/ai/aiFlash/index'));
      });
    },
    meta: {
      title: 'ai快讯配置',
      icon: 'el-icon-user-solid'
    }
  }]
}, {
  path: '/statistical',
  component: Layout,
  name: 'statistical',
  hidden: true,
  meta: {
    title: '统计模块',
    icon: 'el-icon-user-solid'
  },
  children: [{
    path: 'module',
    name: 'Module',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistical/module/index'));
      });
    },
    meta: {
      title: 'app崩溃记录',
      icon: 'el-icon-user-solid'
    }
  }]
}, {
  path: '/settings',
  component: Layout,
  name: 'settings',
  hidden: true,
  meta: {
    title: '系统设置',
    icon: 'el-icon-switch-button'
  },
  children: [{
    path: 'authority',
    name: 'Authority',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/settings/authority/index'));
      });
    },
    meta: {
      title: '权限管理',
      icon: 'el-icon-set-up'
    }
  }, {
    path: 'management',
    name: 'Management',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/settings/management/index'));
      });
    },
    meta: {
      title: '管理员管理',
      icon: 'el-icon-connection'
    }
  }, {
    path: 'version',
    name: 'Version',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/settings/version/index'));
      });
    },
    meta: {
      title: '版本控制',
      icon: 'el-icon-document-copy'
    }
  }]
}, {
  path: '/navMenu',
  component: Layout,
  hidden: true,
  name: 'navMenu',
  meta: {
    title: '导航菜单',
    icon: 'el-icon-menu'
  },
  children: [{
    path: 'menuManager',
    name: 'MenuManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/navMenu/menuManager/index'));
      });
    },
    meta: {
      title: '菜单管理',
      icon: 'el-icon-menu'
    }
  }]
} // // 菜单信息
// {
//   path: '/menu',
//   component: Layout,
//   name: 'menu',
//   meta: { title: '菜单信息', icon: 'el-icon-setting' },
//   children: [
//     {
//       path: 'systemMenu',
//       name: 'SystemMenu',
//       component: () => import(/* webpackChunkName: "systemMenu" */ '@/views/menu/systemMenu/index'),
//       meta: { title: '系统菜单', icon: 'el-icon-setting' }
//     }
//   ]
// }
];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
/**
 * 重写路由的push方法
 */


var routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(function (error) {
    return error;
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;