import request from '@/utils/request';
export function aiQueryPage(data) {
  return request({
    url: '/defaultMessage/auth/queryPage',
    method: 'post',
    data: data
  });
}
export function aiUpdateById(data) {
  return request({
    url: '/defaultMessage/auth/updateById',
    method: 'post',
    data: data
  });
}
export function aiSave(data) {
  return request({
    url: '/defaultMessage/auth/save',
    method: 'post',
    data: data
  });
}
export function aifeedbackQueryPage(data) {
  return request({
    url: '/feedback/auth/queryPage',
    method: 'post',
    data: data
  });
}
export function aifeedbackUpdateById(data) {
  return request({
    url: '/defaultMessage/auth/updateById',
    method: 'post',
    data: data
  });
}
export function aifeedbackSave(data) {
  return request({
    url: '/defaultMessage/auth/save',
    method: 'post',
    data: data
  });
}
export function aifeedbackExport(data) {
  return request({
    url: '/feedback/auth/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
export function aicurrencyAiqueryPage(data) {
  return request({
    url: '/feedback/auth/export',
    method: 'post',
    data: data
  });
}
export function aicurrencyAisave(data) {
  return request({
    url: '/currencyAi/auth/save',
    method: 'post',
    data: data
  });
}
export function aicurrencyAiqueryPageout(data) {
  return request({
    url: '/currencyAi/auth/queryPage',
    method: 'post',
    data: data
  });
}
export function aicurrencyAiputCurrencyByIds(data) {
  return request({
    url: '/currencyAi/auth/putCurrencyByIds',
    method: 'post',
    data: data
  });
}
export function appFailauth(data) {
  return request({
    url: '/appFail/auth/queryPage',
    method: 'post',
    data: data
  });
}